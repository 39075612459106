import { renderRichText } from "gatsby-source-contentful/rich-text"
import classNames from "classnames"
import { GatsbyImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import imgMan from "assets/icon-man.png"
import imgWoman from "assets/icon-woman.png"
import imgKid from "assets/icon-kid.png"
import CategoryList from "../category/simpleList"
import "./styles.scss"

const options = {
  renderNode: {
    "embedded-asset-block": node => {
      const { id, title, gatsbyImageData } = node.data.target

      if (!gatsbyImageData) return null

      return <GatsbyImage image={gatsbyImageData} key={id} alt={title} />
    }
  }
}

const SpecTab = ({ product }) => <ul>
  {product.specs && product.specs.map(spec => (
    <li className="product-spec-item" key={spec}>{spec}</li>
  ))}
</ul>

const SizeTab = ({ product }) => <div>
  {product.size_images.map(sizeImage => (
    <GatsbyImage
      image={sizeImage.gatsbyImageData}
      key={sizeImage.id}
      alt={sizeImage.title}
      objectFit="contain"
      style={{ maxHeight: 500, maxWidth: 500 }}
    />
  ))}
</div>
  
const tabs = [
  {
    name: '商品描述',
    Component: SpecTab
  },
  {
    name: '尺寸表',
    Component: SizeTab
  }
]

export default ({ productInfo, allProductTags }) => {
  const [selectedColorIndex, setSelectedColorIndex] = useState(0)
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const TabComponent = tabs[selectedTabIndex].Component

  const handleColorClick = index => {
    setSelectedColorIndex(index)
  }

  const handleTabBtnClick = index => {
    setSelectedTabIndex(index)
  }

  return (
    <div className="product-detail-page-container">
      <CategoryList list={allProductTags} />
      <div style={{ flex: 1 }}>
        <div className="product-tags-container">
          {productInfo.product_tags.map(t => (
            <span className="product-tags" key={t.name}>{t.name}</span>
          ))}
        </div>
        <div className="product-main-container">
          <div className="product-main-body">
            <div className="product-main-image">
              <GatsbyImage
                image={productInfo.product_colors[selectedColorIndex].gatsbyImageData}
                alt={productInfo.product_colors[selectedColorIndex].title}
              />
            </div>
          </div>
          <div className="product-info">
            <div className="product-info-indicators">
              <div className="size-fit-indicator">
                <img className={classNames('size-fit-indicator-men', !productInfo.fit_men && 'disabled')} src={imgMan} alt="男裝適用" />
                <img className={classNames('size-fit-indicator-women', !productInfo.fit_women && 'disabled')} src={imgWoman} alt="女裝適用" />
                <img className={classNames('size-fit-indicator-kids', !productInfo.fit_kids && 'disabled')} src={imgKid} alt="童裝適用" />
              </div>

              <div className="colors-indicator">
                <div className="indicator-numbers">{productInfo.product_colors.length}</div>
                COLORS
              </div>

              <div className="size-count-indicator">
                <div className="indicator-numbers">{productInfo.size_count}</div>
                SIZES
              </div>            
            </div>

            <h1 className="product-name">{productInfo.name}</h1>
            <div className="selected-color-name">{productInfo.product_colors[selectedColorIndex].name}</div>

            <div className="product-color-container">
              {productInfo.product_colors.map((productColor, i) => (
                <div className="product-color-images" onClick={() => handleColorClick(i)} key={productColor.id}>
                  <GatsbyImage
                    image={productColor.gatsbyImageData}
                    alt={productColor.title}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    style={{ height: 80, width: 80 }}
                  />
                </div>
              ))}
            </div>
            <div className="tab-indicator">
              {tabs.map((tab, index) => (
                <span
                  className={classNames('tab-btn', selectedTabIndex === index && 'selected-tab')}
                  onClick={() => handleTabBtnClick(index)}
                  key={tab.name}
                >
                  {tab.name}
                </span>
              ))}
            </div>
            <TabComponent product={productInfo} />
          </div>
        </div>
        {
          productInfo.description && (
            <div className="product-rich-text">{renderRichText(productInfo.description, options)}</div>
          )
        }
      </div>
    </div>
  )
}
