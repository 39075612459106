import React from "react"
import { graphql } from "gatsby"
import Layout from "components/layout"
import SEO from "components/seo"
import ProductDetail from "pages/products/productDetail"

export const Head = ({ data }) => (
  <SEO
    title={data.contentfulProduct.name}
    pathname={`/${data.contentfulProduct.slug}`}
  />
)

export const query = graphql`
  query($id: String!) {
    allContentfulProductTag(sort: { fields: [group_number, order] order: [ASC, DESC] }) {
      edges {
        node {
          id
          name
          group_number
        }
      }
    }
    contentfulProduct(id: { eq: $id }) {
      id
      slug
      name
      fit_men
      fit_women
      fit_kids
      product_tags {
        id
        name
        group_number
      }
      size_count
      size_images {
        id
        title
        gatsbyImageData(layout: CONSTRAINED, width: 500)
      }
      product_colors {
        id
        title
        gatsbyImageData
      }
      specs
      description {
        raw
        references {
          ... on ContentfulAsset {
            __typename
            contentful_id
            id
            title
            gatsbyImageData
          }
        }
      }
    }
  }
`

export default ({ data }) => (
  <Layout>
    <ProductDetail productInfo={data.contentfulProduct} allProductTags={data.allContentfulProductTag.edges} />
  </Layout>
)
