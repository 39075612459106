import classNames from "classnames"
import { Link } from "gatsby"
import React from "react"
import "./styles.scss"

export default ({ list = [] }) => {
  let currentGroupNumber

  return (
    <div className="cate-container hidden-mobile" style={{ maxWidth: 200 }}>
      <div className="fixed-cates">
        <div className="cate-item">
          <Link to="/products">全部商品</Link>
        </div>
        {
          list.reduce((components, tag) => {
            if (tag.node.group_number !== 1) return components

            return components.concat(
              <div key={`cate-${tag.node.id}`} className="cate-item">
                <Link to={`/products?tags=${tag.node.name}`}>{tag.node.name}</Link>
              </div>
            )
          }, [])
        }
      </div>
      {
        list.reduce((components, tag) => {
          if (tag.node.group_number < 2) return components

          let showDivider = false
          // TODO: move filter logic to parent
          if (tag.node.group_number !== currentGroupNumber) {
            showDivider = true
            currentGroupNumber = tag.node.group_number
          }

          return components.concat(
            <div
              key={`cate-${tag.node.id}`}
              className={classNames('cate-item', showDivider && `with-top-border-${tag.node.group_number}`)}
            >
              <Link to={`/products?tags=${tag.node.name}`}>{tag.node.name}</Link>
            </div>
          )
        }, [])
      }
    </div>
  )
}
